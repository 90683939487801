<template>
  <div>
    <div class="company" v-if="enterpriseType">
      <div class="company_left">
        <upload-image
          :imageUrl.sync="enterpriseInfo.companyLogoLook"
          :saveUrl.sync="enterpriseInfo.companyLogo"
        />
        <el-form
          :model="enterpriseInfo"
          :inline="true"
          :rules="rulesCompanyInfo"
          ref="rulesCompanyInfo"
          class="companyInfo"
        >
          <div class="info">
            <div>
              <div class="fullName" v-if="!isShowInput">
                <div class="companyFullName">
                  {{ enterpriseInfo.companyFullName }}
                </div>
                <i class="jr-iconjr-icon-edit1" @click="editClick" />
              </div>

              <div class="fullName" v-else>
                <el-form-item prop="companyFullName">
                  <el-input v-model="enterpriseInfo.companyFullName" />
                </el-form-item>

                <el-button type="primary" @click="sureInfo">保 存</el-button>
              </div>
            </div>
            <div class="updateTime">
              更新时间：{{ enterpriseInfo.updateTime }}
            </div>
          </div>
        </el-form>
      </div>
      <div class="company_right">
        <div>创建人：{{ enterpriseInfo.createName }}</div>
        <div>修改人：{{ enterpriseInfo.updateName }}</div>
        <div>收录时间：{{ enterpriseInfo.createTime }}</div>
      </div>
    </div>
    <el-form
      v-else
      :model="enterpriseInfo"
      :inline="true"
      :rules="rulesCompanyInfo"
      ref="rulesCompanyInfo"
      class="companyInfo"
    >
      <el-form-item class="avatar">
        <upload-image :saveUrl.sync="enterpriseInfo.companyLogo" />
      </el-form-item>
      <el-form-item label="公司全称：" prop="companyFullName">
        <el-input
          v-model="enterpriseInfo.companyFullName"
          class="lager-input"
        />
      </el-form-item>
    </el-form>
    <div class="tabs">
      <el-tabs v-model="activeName" class="ly_tabs" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <info
            ref="info"
            :enterpriseInfo.sync="enterpriseInfo"
            :enterpriseType="enterpriseType"
          ></info>
        </el-tab-pane>
        <el-tab-pane label="标签信息" name="second">
          <label-info
            ref="labelInfo"
            :enterpriseType="enterpriseType"
            :companyId="String(companyCode)"
          />
        </el-tab-pane>
        <el-tab-pane label="手动标签" name="third">
          <labelManagement
            ref="labelManagement"
            :enterpriseType="enterpriseType"
            :companyCode="String(companyCode)"
            :companyId="String(companyId)"
          ></labelManagement>
        </el-tab-pane>
        <el-tab-pane label="半自动化标签" name="four">
          <robotizationlabel
            ref="robotizationlabel"
            :activeId="activeId"
            v-show="headTabActive == 2"
            :companyId="String(companyId)"
            :type="type"
            :companyCode="String(companyCode)"
          ></robotizationlabel>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 半自动化标签不展示按钮 -->
    <div
      class="editEnterprise"
      ref="editEnterprise"
      v-if="this.activeName != 'four'"
    >
      <div class="bottom">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>
    </div>
    <div
      style="height: 50px; background: #fff; border: 1px solid #EBEEF5"
      v-if="this.activeName == 'four'"
    ></div>
  </div>
</template>

<script>
import {
  updateClientEnterprise,
  insertClientEnterprise,
  getClientEnterpriseOne
} from "@/api/customCompany";
import {
  insertCompanyLabel,
  updateCompanyLabel,
  selectCompanyLabel
} from "@/api/enterprise";
import { savelabel, updateInformationLabel } from "@/api/datement.js";
import UploadImage from "@/components/UploadImage.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import CascaderArea from "@/components/CascaderAreaEnter.vue";
import DataTime from "@/components/DataTime.vue";
import Info from "./info.vue";
import labelManagement from "./labelManagement.vue";
import LabelInfo from "./labelInfo.vue";
import robotizationlabel from "./robotizationlabel.vue";
const defaultEnterpriseInfo = Object.freeze({
  companyOperationalStatus: "", // 运营状态
  companyLogo: "",
  companyLogoLook: "",
  companyShortName: "", // 公司简称
  companyName: "", // 公司名称
  companyFullName: "", // 公司全称
  companyLevel: "", // 专精特新
  industryCategoryList: [], // 行业类别
  industryCategoryChild: [], // 行业类别
  companyBriefIntroduction: "", // 简介
  legalRepresentative: "", // 法人
  registeredCapital: "", // 注册资本
  establishmentDate: "", // 成立日期
  country: "", // 国家
  province: "", // 省份
  city: "", // 城市
  telephone: "", // 电话
  email: "", // 邮箱
  socialSecurityNumber: "", // 社保人数
  formerName: "", // 曾用名
  englishName: "", // 英文名
  website: "", // 网址
  companyAddress: "", // 公司名称
  businessScope: "", // 经营范围
  financingRounds: "", // 融资轮次
  enterpriseScale: "", // 企业规模
  companyLevelType: "", //级别
  certificationYear: "", //认定年份
  region: ""
});
export default {
  name: "customerMain",
  components: {
    UploadImage,
    DicRadioGroup,
    DicCheckboxGroup,
    CascaderArea,
    DataTime,
    Info,
    LabelInfo,
    robotizationlabel,
    labelManagement
  },
  data() {
    return {
      isLinkShow: false,
      isShow: false,
      query: {
        judgeArray: [
          { label: "是", value: 1 },
          { label: "否", value: 0 }
        ],
        companyOperationalStatus: [
          {
            label: "运营中"
          },
          {
            label: "已关闭"
          },
          {
            label: "已转型"
          }
        ],
        socialSecurityNumberArray: [
          {
            label: "10人以下"
          },
          {
            label: "10-15人"
          },
          {
            label: "15-30人"
          },
          {
            label: "30-50人"
          },
          {
            label: "50-100人"
          },
          {
            label: "100-150人"
          },
          {
            label: "150-500人"
          },
          {
            label: "500-1000人"
          },
          {
            label: "1000-2000人"
          },
          {
            label: "2000人以上"
          },
          {
            label: "不明确"
          }
        ]
      },
      activeName: "first",
      isShowInput: false,
      searchBarFixed: false,
      saveCompanyLogo: "",
      teamInfoTable: [], // 团队信息数据
      rulesCompanyInfo: {
        companyFullName: [
          { required: true, message: "请输入公司全称", trigger: "blur" }
        ]
      },
      enterpriseInfo: { ...defaultEnterpriseInfo },
      members: [],
      companyId: "", // 企业主键
      headTabActive: this.$route.query.headTabActive, // tab类名控制
      activeId: this.$route.query.id, // 点击编辑进来的 那一项id
      // companyFullNameRule: false, // 判断公司全称有没有
      allArr: [], //回显的数据
      flag: true //判断是否点击了手动标签去调用了getById这个查询接口,全局只能调用一次
    };
  },
  computed: {
    companyCode() {
      return this.$route.query.id;
    },
    enterpriseType() {
      return Number(this.$route.query.enterpriseType);
    },
    type() {
      return Number(this.$route.query.type);
    }
  },
  watch: {
    "enterpriseInfo.id": {
      handler(newVal) {
        if (this.enterpriseInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true
    },
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "third" && this.flag) {
          // this.handleClick();
          this.$refs.labelManagement.getBylabelId();
        }
      }
    }
  },
  created() {
    // 是编辑才调用查询接口
    if (this.enterpriseType) {
      this.search();
      this.searchLabel();
    }
    if (!this.enterpriseType) {
      this.isShow = true;
    }
  },
  mounted() {
    document.querySelector(".el-tabs__content").style.minHeight =
      document.body.clientHeight - 368 + "px";
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      // 当前为手动标签页面,且id存在(存在的前提是保存了基本信息),且没有调用过getById
      // if (tab.index == 2 && (this.companyId || this.companyCode) && this.flag) {
      //   this.$refs.labelManagement.getBylabelId();
      //   if (this.$refs.labelManagement.labeList) {
      //     this.flag = false;
      //   }
      // }
      if (tab.index == 2 && (this.companyId || this.companyCode)) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    searchArae(val) {
      this.enterpriseInfo.country = val.country;

      this.enterpriseInfo.province = val.province;

      this.enterpriseInfo.city = val.city;
    },
    updateCode(val, tag) {
      switch (tag) {
        case "行业":
          this.enterpriseInfo.industryCategoryChild = val;
          break;

        case "技术":
          this.enterpriseInfo.techChildrenId = val;
          break;

        default:
          break;
      }
    },
    dateClick(year, month, day) {
      this.enterpriseInfo.year = year;
      this.enterpriseInfo.day = day;
      this.enterpriseInfo.month = month;
    },
    // 查询
    async search() {
      const res = await getClientEnterpriseOne({
        id: this.companyCode
      });

      this.enterpriseInfo = res.data;
      this.enterpriseInfo.oldCompanyFullName=this.enterpriseInfo.companyFullName

      // this.enterpriseInfo.industryCategoryList = this.formatString(
      //   this.enterpriseInfo.industryCategoryList
      // );
      if (this.enterpriseInfo.industryCategory) {
        this.enterpriseInfo.industryCategoryList = this.formatString(
          this.enterpriseInfo.industryCategory
        );
      } else {
        this.enterpriseInfo.industryCategoryList = [];
      }

      // 查询的时候处理企业资质
      if (this.enterpriseInfo.companyLevel) {
        if (this.enterpriseInfo.companyLevel.includes("/")) {
          this.allArr = this.enterpriseInfo.companyLevel.split("/");
          this.allArr.forEach(item => {
            if (item == "专精特新小巨人") {
              this.$refs.info.checked2 = true;
              this.$refs.info.disone = true;
              this.$refs.info.le = true;
              this.$refs.info.leone = false;
              this.$refs.info.letwo = true;
            } else if (item == "专精特新") {
              this.$refs.info.checked1 = true;
              this.$refs.info.distwo = true;
              this.$refs.info.le = true;
              this.$refs.info.leone = true;
              this.$refs.info.letwo = true;
            } else {
              this.$refs.info.checked3 = true;
            }
          });
        } else if (this.enterpriseInfo.companyLevel.includes("专精特新")) {
          this.$refs.info.checked1 = true;
          this.$refs.info.distwo = true;
          this.$refs.info.le = true;
          this.$refs.info.leone = true;
          this.$refs.info.letwo = true;
        } else if (
          this.enterpriseInfo.companyLevel.includes("专精特新小巨人")
        ) {
          this.$refs.info.checked2 = true;
          this.$refs.info.disone = true;
          this.$refs.info.le = true;
          this.$refs.info.leone = false;
          this.$refs.info.letwo = true;
        } else {
          this.$refs.info.checked3 = true;
        }
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    editClick() {
      this.isShowInput = true;
    },

    // 修改公司全称
    async sureInfo() {
      this.$refs.rulesCompanyInfo.validate(async valid => {
        if (valid) {
          const res = await updateClientEnterprise(this.enterpriseInfo);
          this.isShowInput = false;
        }
      });
    },
    // 新增公司全称和公司logo
    saveCompanyInfo() {
      this.$refs.rulesCompanyInfo.validate(async valid => {
        if (valid) {
          const params = {
            companyAddParam: this.enterpriseInfo,
            members: this.members
          };
          const res = await insertClientEnterprise(params);

          if (res.code === 200) {
            this.$message.success("新增成功");
          }
        }
      });
    },
    // 全部保存
    async saveItem() {
      this.$refs.rulesCompanyInfo.validate(async valid => {
        if (!valid) {
          this.$message.error("请输入公司全称");
          // this.$message({
          //   message: "请输入公司全称",
          //   type: "warning",
          // });
        } else {
          Object.assign(this.enterpriseInfo, {
            Authorization: this.Authorization
          });
          switch (this.activeName) {
            case "first":
              let res;

              if (this.$refs.info.enterpriseInfo.day) {
                this.$refs.info.$refs.rulesCompanyInfoOne.validate(
                  async valid => {
                    if (valid) {
                      if (this.enterpriseInfo.industryCategoryChild) {
                        this.enterpriseInfo.industryCategoryList = this.enterpriseInfo.industryCategoryList.concat(
                          this.enterpriseInfo.industryCategoryChild
                        );
                      }
                      // 保存的时候处理企业资质
                      if (
                        this.$refs.info.checked1 &&
                        this.$refs.info.checked3
                      ) {
                        this.enterpriseInfo.companyLevel =
                          "专精特新" + "/" + "规上企业";
                      } else if (
                        this.$refs.info.checked2 &&
                        this.$refs.info.checked3
                      ) {
                        this.enterpriseInfo.companyLevel =
                          "专精特新小巨人" + "/" + "规上企业";
                      } else if (
                        !this.$refs.info.checked1 &&
                        !this.$refs.info.checked2 &&
                        this.$refs.info.checked3
                      ) {
                        this.enterpriseInfo.companyLevel = "规上企业";
                      } else if (
                        this.$refs.info.checked1 &&
                        !this.$refs.info.checked2 &&
                        !this.$refs.info.checked3
                      ) {
                        this.enterpriseInfo.companyLevel = "专精特新";
                      } else if (
                        !this.$refs.info.checked1 &&
                        this.$refs.info.checked2 &&
                        !this.$refs.info.checked3
                      ) {
                        this.enterpriseInfo.companyLevel = "专精特新小巨人";
                      } else {
                        this.enterpriseInfo.companyLevel = "";
                      }

                      // 处理认定年份
                      if (
                        this.enterpriseInfo.certificationYear &&
                        this.enterpriseInfo.certificationYear !== "0"
                      ) {
                        const date = new Date(
                          this.enterpriseInfo.certificationYear
                        );
                        this.enterpriseInfo.certificationYear = date.getFullYear();
                      }

                      // this.enterpriseInfo.industryCategory = [
                      //   ...new Set(this.enterpriseInfo.industryCategory),
                      // ];
                      if (this.enterpriseType) {
                        // 编辑
                        res = await updateClientEnterprise(
                          this.$refs.info.enterpriseInfo
                        );
                      } else {
                        // 新增
                        res = await insertClientEnterprise(this.enterpriseInfo);
                      }

                      if (res.code === 200) {
                        if (res.msg !== "修改成功" && res.msg != null) {
                          this.companyId = res.data;
                          this.companyCode = res.data;
                          this.search();
                        }
                        this.companyId = res.data;

                        this.$message.success(
                          `${this.enterpriseType ? "编辑" : "新增"}成功`
                        );

                        this.activeName = "second";
                      } else {
                        this.$message.error(res.msg);
                      }
                    }
                  }
                );
              } else {
                this.$message.error("请完善日期");
              }
              break;
            case "second":
              //最终组装的数组
              let arr = [];
              //开始添加
              this.$refs.labelInfo.companyLabelParam.companyKeywordAddParamList.forEach(
                item => {
                  let keyword = item.keyword;
                  let keywordDescribe = item.keywordDescribe;
                  let companyLabelList = [];
                  // 遍历我添加的数组
                  item.companyLabelList.forEach(child => {
                    // 遍历总数组
                    this.$refs.labelInfo.labelOptions.forEach(item1 => {
                      if (item1.labelKeywordResultList[0].id == child.labelId) {
                        item1.labelKeywordResultList.forEach(name => {
                          name = {
                            groupLabelId: name.groupLabelPrefix,
                            groupLabelName: name.groupLabelType,
                            labelId: name.id,
                            labelName: name.labelName,
                            labelStatus:
                              item1.labelKeywordResultList[0].id == name.id
                                ? 1
                                : 0
                          };
                          companyLabelList.push(name);
                        });
                      }
                    });
                  });

                  let all = {
                    keyword: keyword,
                    keywordDescribe: keywordDescribe,
                    companyLabelList: companyLabelList
                  };
                  arr.push(all);
                }
              );

              // 最终提交后端的数据
              let companyLabelParam = {
                keywordVersion: this.$refs.labelInfo.companyLabelParam
                  .keywordVersion, //关键字版本
                companyId: this.companyCode ? this.companyCode : this.companyId,
                companyKeywordAddParamList: arr,
                type: this.type
              };

              if (this.enterpriseType) {
                this.$refs.labelInfo.$refs.labelInfoForm.validate(
                  async valid => {
                    if (valid) {
                      this.$refs.labelInfo.companyLabelParam.companyId = this.companyCode;
                      this.$refs.labelInfo.companyLabelParam.type = this.type;
                      // 编辑
                      if (this.enterpriseType) {
                        // 编辑
                        if (this.$refs.labelInfo.companyLabelParam) {
                          res = await updateCompanyLabel(companyLabelParam);
                        } else {
                          res = await updateCompanyLabel(companyLabelParam);
                        }
                      }

                      if (res.code == 200) {
                        this.$message.success(
                          `${this.enterpriseType ? "编辑" : "新增"}成功`
                        );
                        this.activeName = "third";
                      } else {
                        this.$message.error(res.msg);
                      }
                    }
                  }
                );
              } else {
                if (!this.companyId) {
                  this.$message({
                    showClose: true,
                    message: "请先保存上个页面的数据再进行下一步操作",
                    type: "error"
                  });
                } else {
                  this.$refs.labelInfo.$refs.labelInfoForm.validate(
                    async valid => {
                      if (valid) {
                        this.$refs.labelInfo.companyLabelParam.companyId = this.companyId;
                        this.$refs.labelInfo.companyLabelParam.type = this.type;
                        // if (this.enterpriseType) {
                        //   // 编辑
                        //   if (this.$refs.labelInfo.companyLabelParam) {
                        //     res = await updateCompanyLabel(
                        //       this.$refs.labelInfo.companyLabelParam
                        //     );
                        //   } else {
                        //     res = await updateCompanyLabel(
                        //       this.$refs.labelInfo.companyLabelParam
                        //     );
                        //   }
                        // } else {
                        // 新增
                        res = await insertCompanyLabel(companyLabelParam);
                        // }

                        if (res.code == 200) {
                          this.$message.success(
                            `${this.enterpriseType ? "编辑" : "新增"}成功`
                          );
                          this.activeName = "third";
                        } else {
                          this.$message.error(res.msg);
                        }
                      }
                    }
                  );
                }
              }
              break;
            case "third":
              if (!this.companyId) {
                this.$message.error("请先保存基本信息的数据再进行下一步操作！");
              } else {
                let query = [];

                this.$refs.labelManagement.radiolist.forEach(el => {
                  if (el.labelparam.labelNameList.length > 0) {
                    el.labelparam.companyId = this.companyCode
                      ? this.companyCode
                      : this.companyId;
                    query.push(el.labelparam);
                  }
                });
                if (query.length > 0) {
                  // if (this.$refs.labelManagement.labeList.length == 0) {
                  if (!this.$refs.labelManagement.labeList.length) {
                    savelabel(query).then(response => {
                      if (response.code == 200) {
                        this.$message.success("保存成功");
                        // this.$router.go(-1);
                        this.activeName = "four";
                      } else {
                        this.$message.error(response.data.message);
                      }
                    });
                  } else {
                    updateInformationLabel(query).then(response => {
                      if (response.code == 200) {
                        this.$message.success("编辑成功");
                        // this.$router.go(-1);
                        this.activeName = "four";
                      } else {
                        this.$message.error(response.data.message);
                      }
                    });
                  }
                } else {
                  this.$message.warning("请勾选标签！");
                }
              }

              break;
            case "four":
              break;
            default:
              break;
          }
        }
      });
    },
    cancelItem() {
      this.$router.go(-1);
      this.$router.push({
        path: "/customerCompany"
      });
    },
    // 企业标签查询
    async searchLabel() {
      const { data: res } = await selectCompanyLabel({
        companyId: this.companyCode
      });
      this.$refs.labelInfo.companyLabelParam = res;

      // 解决企业标签的关键词至标签编号新增时为空时,输入框不展示
      if (
        !res.companyKeywordAddParamList ||
        !res.companyKeywordAddParamList[0].companyLabelList
      ) {
        this.$refs.labelInfo.companyLabelParam = {
          keywordVersion: "",
          companyKeywordAddParamList: [
            {
              companyLabelList: [
                {
                  groupLabelId: "", // 标签组编号
                  groupLabelName: "", // 标签组名称
                  labelId: "", // 标签编号
                  labelName: "", // 标签内容
                  companyKeywordId: null,
                  superiorLabel: null // 上级标签
                }
              ],
              keyword: "", // 关键词
              keywordDescribe: "" // 关键词数据
            }
          ]
        };
      } else {
        // 如果标签内容有数据的话就将校验关闭
        // this.$refs.labelInfo.rules.labelName[0].required = false;
        this.$refs.labelInfo.companyLabelParam = res;
        // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
        res.companyKeywordAddParamList.forEach(item => {
          item.companyLabelList.forEach(child => {
            child.labelName = child.groupLabelName + "/" + child.labelName;
          });
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.company {
  background: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company_left {
    display: flex;
    .info {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .fullName {
        display: flex;
        align-items: center;
        .jr-iconjr-icon-edit1:hover {
          color: #4d77ff;
        }
        .el-button {
          margin-left: 10px;
        }
        .companyFullName {
          font-size: 24px;
          color: #363e4d;
        }
        i {
          margin-left: 10px;
        }
      }

      .updateTime {
        color: #7c7f8e;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .company_right {
    font-size: 12px;
    color: #7c7f8e;
  }
}
/deep/ .el-tabs__content {
  overflow: auto;
}
.companyInfo {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0;
  }
  .avatar {
    margin-right: 20px;
  }
}

.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}

// tabs样式
.ly_tabs {
  background: #fff;
  padding: 0 20px;
}
.el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 15px;
}
.el-tabs__nav-wrap {
  overflow: hidden;
  margin-bottom: -1px;
  position: relative;
}
.company .company_left .info .fullName[data-v-92b4247e] {
  margin-bottom: 10px;
}
// /deep/.tabs {
//   height: calc(100vh - 312px) !important;
//   overflow: hidden !important;
// }
</style>
