<template>
  <div>
    <div class="caseDataParent">
      <div class="caseDataParent_type">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <caseDataInfo ref="caseDataInfo" :caseDataInfo="caseDataInfo" />
          </el-tab-pane>
          <el-tab-pane label="关联信息" name="second">
            <related-information
              ref="information"
              :projectDataType="projectDataType"
              :caseDataInfo="caseDataInfo"
            />
          </el-tab-pane>
          <el-tab-pane label="标签信息" name="third">
            <label-info ref="labelInfo"></label-info>
          </el-tab-pane>
          <el-tab-pane label="手动标签" name="four">
            <labelManagement
              ref="labelManagement"
              :projectDataType="projectDataType"
              :saveCaseDataId="Number(saveCaseDataId)"
            ></labelManagement>
          </el-tab-pane>
          <el-tab-pane label="半自动标签" name="five">
            <robotizationlabel
              ref="robotizationlabel"
              :projectDataType="projectDataType"
              :companyCode="Number(saveCaseDataId)"
            ></robotizationlabel>
          </el-tab-pane>
        </el-tabs>
        <div
          style="height: 50px; background: #fff; border-top: 1px solid #dbdbdb;"
          v-if="this.activeName == 'five'"
        ></div>
        <div class="bottom" v-if="activeName !== 'five'">
          <el-button type="primary" @click="saveItem">保存</el-button>
          <el-button @click="cancelItem">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入子组件
import CaseDataInfo from "./CaseDataInfo.vue";
import relatedInformation from "./relatedInformation.vue";
import labelInfo from "./labelInfo.vue";
import labelManagement from "./labelManagement.vue";
import robotizationlabel from "./robotizationlabel.vue";
// 基本信息-关联信息新增 / 基本信息-关联信息编辑 / 基本信息-关联信息查询
// 标签信息新增 / 标签信息编辑 / 标签信息查询
// 手动打标签新增 / 收到打标签编辑
import {
  addCaseData,
  updateCaseData,
  findScheme,
  insertPlanLabel,
  updatePlanLabel,
  selectPlanLabelDetail,
  insertPlanLabelManual,
  updatePlanLabelManual,
} from "@/api/caseData.js";
const defaultCaseDataInfo = Object.freeze({
  planTitle: "", // 案例名称
  shareImage: [], //案例分享图
  simgpathImg: [], // 保存的案例分享图
  // imgpaths: [], //案例封面图
  // simgpathImg: [], // 保存的案例图片
  planCountry: "", // 案例地点国家
  planProvince: "", // 案例地点省份
  planCity: "", // 案例地点市
  planAddress: "", //具体地址
  videoResults: null, // 案例视频
  video: null, //案例视频的id
  planDescribe: "", // 案例简介
  planUrl: "", // 案例网址
  softwareHardware: "", // 案例类型
  softwareHardwareList: [], // 案例类型
  imgPath: [], // 案例图片
  temporarypathImg: [], //保存的案例图片
       customerPainPoint: "", //客户痛点
      customerSolution: "", //解决方案
      planOutcome: "", //案例成果
      planFile: "", //案例附件
  // 遍历四个 (案例客户)
  // planCustomerList: [
  //   {
  //     customerPainPoint: "", //客户痛点
  //     customerSolution: "", //解决方案
  //     planOutcome: "", //案例成果
  //     planFile: "", //案例附件
  //   },
  // ],
  planId: null, //研究报告
  scene: "", // 应用场景（关联场景）
  companyCustomerName: "", // 客户企业
  reportName: "", //研究报告名称
  reportId: "", //研究报告id
  serviceCompanyFullName: "", //所属企业
  companyServiceId: "", //所属企业id
  serviceCompanyName: "", // 企业简称
  productId: "", //关联产品
  productType: "", // 产品类型
  sceneId: "", // 关联场景
  sceneName: "", //关联场景名称
  association: "", // 案例关联方案
  clientCompanyName: "", //智参企业
  id: "",
});
export default {
  name: "CaseDataParent",
  components: {
    CaseDataInfo,
    relatedInformation,
    labelInfo,
    labelManagement,
    robotizationlabel,
  },
  data() {
    return {
      activeName: "first", // 当前标签页
      saveCaseDataId: "", // 数据唯一id,新增赋值的
      caseDataInfo: { ...defaultCaseDataInfo },
    };
  },
  computed: {
    // 0 新增 1 编辑 点击新增/编辑页面按钮调整路径带过来的
    projectDataType() {
      return Number(this.$route.query.projectDataType);
    },
    // 数据唯一id
    planId() {
      return Number(this.$route.query.editInfo);
    },
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "four") {
          this.$refs.labelManagement.getLabel();
        }
      },
    },
  },
  created() {
    // 如果是编辑，就将父页面传递的id赋值给saveCaseDataId
    if (this.projectDataType) {
      this.saveCaseDataId = this.$route.query.editInfo;
      this.findScheme(); // 查询基本信息-关联信息
      this.selectPlanLabelDetail(); //标签信息查询
      // this.selectProductLabel();
      // this.seach();
    }
  
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      
      if (tab.index == 2 && this.saveCaseDataId) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 保存接口
    saveItem() {
      let res;
      // 判断基本信息页面的必填字段有没有填
      // let flag = false;
      switch (this.activeName) {
        case "first":
          this.$refs.caseDataInfo.$refs.rulesCaseDataInfo.validate(
            async (valid) => {
              if (valid) {
                // flag = true;
                // 调用子组件的数据处理
                this.$refs.caseDataInfo.handleDta();
                // 0 新增 1 编辑
                if (this.projectDataType || this.saveCaseDataId) {
                  res = await updateCaseData(this.caseDataInfo);
                } else {
                  res = await addCaseData(this.caseDataInfo);
                }

                if (res.code === 200) {
                  this.$message.success(
                    `${this.projectDataType ? "编辑" : "新增"}成功`
                  );

                  if (res.data !== "修改成功" && res.data !== "") {
                    this.saveCaseDataId = res.data;
                    this.caseDataInfo.id = res.data;
                  }

                  this.activeName = "second";
                } else {
                  this.$message.error(res.msg);
                }
              }
            }
          );

          break;
        case "second":
          // if (!flag) {
          //   this.$message.error("请先完善基本信息页面必填项");
          // } else {
          this.$refs.information.$refs.informationForm.validate(
            async (valid) => {
              if (valid) {
                // 存在 编辑 不存在 新增
                if (this.saveCaseDataId) {
                  res = await updateCaseData(this.caseDataInfo);
                  this.activeName = "third";
                } else {
                  res = await addCaseData(this.caseDataInfo);
                  this.activeName = "third";
                }

                if (res.code === 200) {
                  this.$message.success(
                    `${this.projectDataType ? "编辑" : "新增"}成功`
                  );

                  if (res.data !== "修改成功" && res.data !== "") {
                    this.saveCaseDataId = res.data;
                  }

                  this.activeName = "third";
                } else {
                  this.$message.error(res.msg);
                }
              }
            }
          );
          // }

          break;
        case "third":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.planLabelParam.planKeywordAddParamList.forEach(
            (item) => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let planLabelList = [];
              // 遍历我添加的数组
              item.planLabelList.forEach((child) => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach((item1) => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach((name) => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0,
                      };
                      planLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                planLabelList: planLabelList,
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let planLabelParam = {
            keywordVersion: this.$refs.labelInfo.planLabelParam.keywordVersion, //关键字版本
            planId: this.saveCaseDataId,
            planKeywordAddParamList: arr,
          };
          // 判断是编辑还是新增 1编辑  0新增
          if (!this.projectDataType) {
            // 如果未保存基本信息
            if (!this.saveCaseDataId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(
                async (valid) => {
                  if (valid) {
                    res = await insertPlanLabel(planLabelParam);
                    if (res.code == 200) {
                      this.$message.success("新增成功!");
                      this.activeName = "four";
                    } else {
                      this.$message.error("新增失败，请稍候重试!");
                    }
                  }
                }
              );
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async (valid) => {
              if (valid) {
                res = await updatePlanLabel(planLabelParam);
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "four";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "four":
          if (!this.saveCaseDataId) {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          } else {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach((el) => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.planId = this.saveCaseDataId;
                query.push(el.labelparam);
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertPlanLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "five";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updatePlanLabelManual(query).then((response) => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "five";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          }

          break;
        case "five":
          break;
        default:
          break;
      }
    },
    // 取消按钮
    cancelItem() {
      this.$router.go(-1);
    },
    // 案例标签信息详情
    async selectPlanLabelDetail() {
      const res = await selectPlanLabelDetail({
        planId: this.saveCaseDataId,
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.planKeywordAddParamList) {
          this.$refs.labelInfo.planLabelParam = {
            planId: "", // 案例主键
            keywordVersion: "", // 关键字版本
            // type: null,
            planKeywordAddParamList: [
              {
                planLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null, // 上级标签
                  },
                ],
                keyword: "", // 关键词
                keywordDescribe: "", // 关键词数据
              },
            ],
          };
        } else {
          this.$refs.labelInfo.planLabelParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.planKeywordAddParamList.forEach((item) => {
            item.planLabelList.forEach((child) => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    },
    // 报告基本信息详情
    async findScheme() {
      const res = await findScheme({ planId: this.saveCaseDataId });
      if (res.code == 200) {
        this.caseDataInfo = res.data;
        if(!this.caseDataInfo.softwareHardwareList){
       
       this.caseDataInfo.softwareHardwareList=[]
     }
     if(!this.caseDataInfo.simgpathImg){
       
       this.caseDataInfo.simgpathImg=[]
     }
     if(!this.caseDataInfo.shareImage){
       
       this.caseDataInfo.shareImage=[]
     }
     
      }
      // 如果客户痛点等为空的情况，就默认复制为空
      if (!res.data.planCustomerList || !res.data.planCustomerList.length > 0) {
        this.caseDataInfo.planCustomerList = [
          {
            customerPainPoint: "", //客户痛点
            customerSolution: "", //解决方案
            planOutcome: "", //案例成果
            planFile: "", //案例附件
          },
        ];
      }

      if (res.data.shareImage[0] == "[]") {
        this.caseDataInfo.shareImage = [];
      }
    
      // 研究报告回显
      this.$refs.information.options1 = [
        {
          reportId: res.data.reportId,
          reportName: res.data.reportName,
        },
      ];
      // 所属企业回显
      this.$refs.information.serviceCompanyoptions = [
        {
          companyCode: res.data.companyServiceId,
          companyFullName: res.data.serviceCompanyFullName,
        },
      ];
      // 关联产品回显
      this.$refs.information.productIdOptions = [
        {
          productCode: res.data.productId,
          productName: res.data.productName,
        },
      ];
      // 关联场景回显
      this.$refs.information.scenarioNameOptions = [
        {
          id: res.data.sceneId,
          sceneName: res.data.sceneName,
        },
      ];
      // 关联方案回显
      this.$refs.information.solutionNameOptions = [
        {
          id: res.data.association,
          solutionName: res.data.solutionName,
        },
      ];
      // 企业智参回显
      this.$refs.information.zcCompanyNameOptions = [
        {
          id: res.data.companyCode,
          companyFullName: res.data.clientCompanyName,
        },
      ];

      // if (res.data.companyCode && res.data.companyName) {
      //   this.caseDataInfo.companyCode = res.data.companyCode;
      //   this.options = [
      //     {
      //       companyCode: res.data.companyCode,
      //       companyFullName: res.data.companyName,
      //     },
      //   ];
      // }
      // if (res.data.planId && res.data.reportName) {
      //   this.options1 = [
      //     {
      //       planId: res.data.planId,
      //       reportName: res.data.reportName,
      //     },
      //   ];
      // }
      // if (this.caseDataInfo.association && this.caseDataInfo.solutionName) {
      //   this.caseDataInfo.association = Number(this.caseDataInfo.association);
      //   this.optionsAssociation = [
      //     {
      //       id: this.caseDataInfo.association,
      //       solutionName: this.caseDataInfo.solutionName,
      //     },
      //   ];
      // }
      // this.caseDataInfo.wisdomLabels = this.formatString(
      //   this.caseDataInfo.wisdomLabel
      // );
      // this.caseDataInfo.sectorss = this.formatString(
      //   this.caseDataInfo.tradeLabel
      // );
      // this.caseDataInfo.labels = this.formatString(this.caseDataInfo.aiLabel);
      // this.caseDataInfo.planKeywords = this.formatString(
      //   this.caseDataInfo.planKeyword
      // );
      // this.caseDataInfo.imgUrl = this.formatString(this.caseDataInfo.imgUrl);
      // this.caseDataInfo.simgpathImg = this.formatString(
      //   this.caseDataInfo.imgpath
      // );
      // this.caseDataInfo.imgpaths = this.caseDataInfo.imgPath;
      // 案例类型
      // if (this.caseDataInfo.softwareHardware) {
      //   this.caseDataInfo.softwareHardwareList =
      //     this.caseDataInfo.softwareHardware.split(",");
      // } else {
      //   this.caseDataInfo.softwareHardwareList = [];
      // }
      // this.caseDataInfo.planTime = this.caseDataInfo.planTime.split("-");
      // this.caseDataInfo.year = Number(this.caseDataInfo.planTime[0]);
      // this.caseDataInfo.month = Number(this.caseDataInfo.planTime[1]);
    },
    // ---------------------------------
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    // 数据处理
    handleData(res) {
      this.editData = res.data;
    },
    // // 查询
    // async seach() {
    //   // 参数-表格序号
    //   const queryInfo = {
    //     productCode: this.productCode,
    //   };
    //   const res = await getFindOne(queryInfo);
    //   if (res.code == 200) {
    //     // this.handleData(res);
    //     this.editData = res.data;

    //     //产品类型转数组
    //     // this.editData.productType = this.formatString(this.editData.productType)
    //     //技术驱动转数组
    //     // this.editData.productAi = this.formatString(this.editData.productAi);
    //     //交付类型转数组
    //     this.editData.solutionDelivery = this.formatString(
    //       this.editData.solutionDelivery
    //     );
    //     //行业字符串转数组
    //     this.editData.solutionIndustry = this.formatString(
    //       this.editData.solutionIndustry
    //     );
    //     //领域字符串转数组
    //     this.editData.domainCategory = this.formatString(
    //       this.editData.domainCategory
    //     );
    //     //技术字符串转数组
    //     this.editData.solutionTechnology = this.formatString(
    //       this.editData.solutionTechnology
    //     );
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.caseDataParent_type {
  padding: 0 20px;
}
.caseDataParent {
  overflow: hidden;
  background-color: #fff;
  /deep/ .el-tabs__content {
    overflow: auto;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
